<template>
  <div>

  </div>
</template>

<script>
  export default {
    data(){
      return{

      }
    },

    mounted() {
      // 跳转到服务商大屏页面
      // this.$router.push('/largeScreen');
      // 打开新的标签页
      window.open('/largeScreen');
    },

    methods:{

    },

  }
</script>

<style lang="scss" scoped>

</style>
